import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

const createRoutineMachineLayer = (props) => {
  const instance = L.Routing.control({
    waypoints: [
      L.latLng(props.benimKonumum[0], props.benimKonumum[1]),
      L.latLng(props.gidilecekYer[0], props.gidilecekYer[1])
    ],
    lineOptions: {
      styles: [
        {
          color: "blue",
          opacity: 0.6,
          weight: 4
        }
      ]
    },

createMarker: function (i, waypoint, n) {
  
        var marker_icon;
        if (i === 0) {
        marker_icon = props.startIcon;
        }
        else if (i == n - 1) {
        marker_icon = props.endIcon
        }
    const marker = L.marker(waypoint.latLng, {
      draggable: false,
      bounceOnAdd: false,
      icon: L.icon({
        iconUrl: marker_icon,
        iconSize: i == n - 1 ?[40, 40]:[50,50],
        shadowSize: [68, 95],
        shadowAnchor: [22, 94]
      })
    });
    return marker;
  }
  
  });


  return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
