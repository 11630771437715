import React from "react";
import Header from "./components/Header";
import "./Home.css";
import { FaFacebookSquare, FaTwitterSquare, GiWaterFountain, RiInstagramFill, AiFillCaretRight, AiFillCaretLeft } from 'react-icons/all';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Carousel } from '@trendyol-js/react-carousel';
import KUDEBLOGO from '../assets/images/KUDEB LOGO 2021.png'
import Slider from "react-slick";
import Item from "./components/Item";
import Video from '../assets/videos/tanitim.mp4';
import Item2 from "./components/Item2";
import HavaDurumu from "./HavaDurumu";
import Item3 from "./components/Item3";
import LOGOROTA from '../assets/images/Rota_Erzurum_Logo_1.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function App({ kategori }) {
  let reactSwipeEl;
  let query = useQuery();
  let history = useHistory();
  const [resim, setResim] = React.useState([
    "url(https://rota.erzurum.bel.tr/image/fon/10.jpg)",
    "url(https://rota.erzurum.bel.tr/image/fon/11.jpg)",
    "url(https://rota.erzurum.bel.tr/image/fon/1.JPG)",
  ]);
  const [sayac, setSayac] = React.useState(0);
  if (query.get("id")) {
    history.push({
      pathname: '/yerbilgisi',
      search: `?query=${query.get("id")}`,
    })
  }
  React.useEffect(() => {

    const timer = setInterval(() => {
      if (sayac < resim.length - 1) setSayac(sayac + 1);
      else setSayac(0);
    }, 10000);
    return () => clearInterval(timer);
  });
  const tumunuGor = () => {
    history.push('/tum')
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const right = () => {
    return (
      <div style={{ marginTop: 116, marginRight: 20 }}><AiFillCaretRight size={30} /></div>
    )
  }
  const left = () => {
    return (
      <div style={{ marginTop: 106, marginLeft: 20 }}><AiFillCaretLeft size={30} /></div>
    )
  }
  return (
    <div className="project">
      <Header yerlesim={true} />
      <div
        className="App"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
          transition: "all 1s",
        }}
      >
        <video className='videoTag' autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
      </div>

      {/* <div className="kudeb_logo"><img src={KUDEBLOGO} style={{ width: 100, height: 70 }} /></div> */}
      <div className="hava_durumu">
        <HavaDurumu />
      </div>

      <div className="text-white text-center center__  mt-5 icerik">

        <div className="left-social" >
          <a href="https://www.facebook.com/erzurumbld" target="_blank">
            <FaFacebookSquare />
          </a>
          <br />
          <a href="https://www.instagram.com/erzurumbld" target="_blank">
            <RiInstagramFill />
          </a>
          <br />
          <a href="https://twitter.com/erzurumbld" target="_blank">
            <FaTwitterSquare />
          </a>
        </div>
        <div className='text-white ' >
        <img src={LOGOROTA} alt="image" style={{ height: 134.16, width: 138.3, }} />
          <h1 className='font-weight-bold mt-5'>Kültür ve Turizm Rehberi</h1>
          <h4>Türkiyenin Zirve Kenti Erzurum</h4>
          <div className="container mt-2  p-3 mobileHome">
            <div className="row align-items-center justify-content-center">

              {
                kategori.map((data, index) => {
                  return (

                    <div className="col-sm-2  mb-1 box_ p-1 text-truncate" key={index} style={{overflow:'hidden' ,display:"inline-block" }} >
                      <Link to={{ pathname: `/kategori/${data.id}`, state: { id: data.id, tipadi: data.tipadi } }}
                        className="rounded box shadow-lg pt-3 pb-3 " >
                        <img src={`https://rota.erzurum.bel.tr/${data.ikon}`} alt="image" className="img-fluid" style={{ height: 40 }} />
                        <span className="mt-2 text-truncate" style={{ fontSize: 16, fontWeight: 700, wordWrap: "break-word",display:"inline-block",overflow:'hidden'  }}>{data.tipadi}</span>
                      </Link>
                    </div>
                  )
                })
              }
            </div>

            {/* <div className="row align-items-center ">
              <Carousel infinite={false} responsive={true}
              className="w-100"
                rightArrow={right()} leftArrow={left()}
              >
                <Item kategori={kategori} />
                <Item2 kategori={kategori} />
                <Item3 kategori={kategori} />
              </Carousel>
            </div> */}
          </div>
          <center className="mt-3">
            <button className="tumunuGorBtn" onClick={() => history.push('map')}>
              Haritada Kefşet
            </button>
          </center>
        </div>
      </div>
    </div>
  );
}
export default App;
