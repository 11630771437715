import React from 'react'
import Header from './components/Header'
import FooterBilgileri from './webservice/FooterBilgileri'
import ContentLoader, { Facebook } from 'react-content-loader'

function Mevzuat() {
    const [footerBilgileri, setFooterBilgisleri] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        FooterBilgileri.then((res) => {
            setFooterBilgisleri(res)
            setLoading(false)
        })
    })
    return (
        <>
            <Header bgColor={true} yerlesim={true}  />
            {
                loading ?
                    <div className="container"  style={{ marginTop: 150 }}>
                    
                        <ContentLoader viewBox="0 0 380 600">
                            <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
                            <rect x="0" y="40" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="80" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="120" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="160" rx="5" ry="5" width="90%" height="30" />
                        </ContentLoader>

                    </div>
                : <div className="container" style={{ marginTop: 150 }}>
                <h3>MEVZUAT</h3>
                {
                    footerBilgileri.filter(name => name.menubaslik.indexOf('Mevzuat') !== -1).map((data, index) => {
                        return (
                            <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                                <div className="col-sm-1">
                                    <img src={`https://rota.erzurum.bel.tr/${data.ikon}`} className="img-fluid" />
                                </div>
                                <div className="col-sm-11">
                                    <span>{data.aciklama}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            }
        </>
    )
}

export default Mevzuat
