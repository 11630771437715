import React from 'react';
import { Container } from 'react-bootstrap';
// import HTMLFlipBook from "react-pageflip";
import Header from './components/Header';
// import Image1 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-01.jpg'
// import Image2 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-02.jpg'
// import Image3 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-03.jpg'
// import Image4 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-04.jpg'
// import Image5 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-05.jpg'
// import Image6 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-06.jpg'
// import Image7 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-07.jpg'
// import Image9 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-09.jpg'
// import Image10 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-10.jpg'
// import Image11 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-11.jpg'
// import Image12 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-12.jpg'
// import Image13 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-13.jpg'
// import Image14 from '../assets/images/kudebKitabi/KUDEB KİTAPÇIĞI-14.jpg'
import './Kudeb.css';
import FooterBilgileri from './webservice/FooterBilgileri';

const Kudeb = () => {
    const [footerBilgileri,setFooterBilgisleri] = React.useState([])
    React.useEffect(()=>{
        FooterBilgileri.then((res)=>{
            setFooterBilgisleri(res)
            console.log(res)
        })
    })
    return (
        <>
        <Header  bgColor={true}/>
        <div className="container" style={{marginTop:150}}>
            <h3>MEVZUAT</h3>
        {
            footerBilgileri.map((data,index)=>{
                if(data.menubaslik === 'Mevzuat')
                    return(
                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                            <div className="col-sm-1">
                                <img src={`https://rota.erzurum.bel.tr/image/ckfinder/images/1.png`} className="img-fluid" style={{width:50,height:50}}/>
                            </div>
                            <div className="col-sm-11">
                                <span>{data.aciklama}</span>
                            </div>
                        </div>
                    )
            })
        }
                    <h3 className="mt-5">SİT ALANI</h3>
        {
            footerBilgileri.map((data,index)=>{
                if(data.menubaslik === 'Sit Alanı')
                    return(
                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                            <div className="col-sm-1">
                                <img src={`https://rota.erzurum.bel.tr${data.ikon}`} className="img-fluid" style={{width:50,height:50}}/>
                            </div>
                            <div className="col-sm-11">
                                <span>{data.aciklama}</span>
                            </div>
                        </div>
                    )
            })
        }
                    <h3 className="mt-5">KENTSEL SİT ALANI</h3>
        {
            footerBilgileri.map((data,index)=>{
                if(data.menubaslik === 'KENTSEL SİT ALANI')
                    return(
                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                            <div className="col-sm-12">
                                <span>{data.aciklama}</span>
                            </div>
                        </div>
                    )
            })
        }
                    <h3 className="mt-5">TARİHİ SİT ALANI</h3>
        {
            footerBilgileri.map((data,index)=>{
                if(data.menubaslik === 'TARİHİ SİT ALANI')
                    return(
                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                           
                            <div className="col-sm-12">
                                <span>{data.aciklama}</span>
                            </div>
                        </div>
                    )
            })
        }
         <h3 className="mt-5">DOĞAL (TABİİ) SİT ALANI</h3>
            {
            footerBilgileri.map((data,index)=>{
                if(data.menubaslik === 'DOĞAL (TABİİ) SİT ALANI')
                    return(
                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                           
                            <div className="col-sm-12">
                                <span>{data.aciklama}</span>
                            </div>
                        </div>
                    )
            })
        }
        </div>
    </>
    )
}
export default Kudeb
