import React, { useEffect ,useState } from 'react'
import Header from './components/Header'
import ReactPlayer from 'react-player'
import './CanliKameralar.css'
function CanliKameralar() {
  
    return (
        <div>
            <Header bgColor={true} yerlesim={true} />
            <div className='container' style={{ marginTop: 150 }}>
                    <h3 className="text-weight-bold">Erzurumu Seyret</h3>
                    <iframe src="https://www.erzurum.bel.tr/canliyayinrota.aspx" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{height:720,width:'100%',borderRadius:10}} title="W3Schools Free Online Web Tutorials"></iframe>
            </div>
        </div>
    )
}

export default CanliKameralar
