import React from 'react'
import Header from './components/Header'

function ErzurumTarihi() {
    return (
        <div>
            <Header bgColor={true} yerlesim={true}  />
            <div className="container" style={{ marginTop: 150,textAlign:'justify' }}>
                <h3 className="text-weight-bold">Erzurum Tarihi</h3>
                <br />

                <span>
                    Doğu Anadolu'nun en büyük kenti olan Erzurum'un MÖ 4900 yıllarında kurulduğu tahmin edilmektedir. Erzurum'u da içine alan bölge tarih boyunca Urartular, Kimmerler, İskitler, Medler, Persler, Parftlar, Romalılar, Sasaniler, Araplar, Selçuklular, Bizanslılar, Sasaniler, Moğollar, İlhanlılar ve Sfaviler gibi çok çeşitli kavim ve milletler tarafından idare edilmiştir.1514 yılında şehir ve çevresini fetheden Osmanlılar, Türkiye Cumhuriyeti'nin kurulduğu 1923 yılına kadar bu topraklarda hüküm sürmüşlerdir.
                    <br />
                    Milli mücadele, milli birlik ve bağımsızlık hareketinin temelinin atıldığı Kongre 23 Temmuz 1919 da Erzurum'da toplamıştır.
                    <br />

                    Erzurum'un bilinen ilk adı Doğu Roma (Bizans) İmparatoru II.Theodosios' a (408-450) izafe edilen Theodosiopolis' ti, şimdiki Erzurum' un yerinde kurulmuştu. IV. asır sonuna doğru Roma imparatorluğu sınırları içine alınmış ve 415 tarihinde Theodosios' un emriyle Şark Orduları Kumandanı Anatolius tarafından kurulmuştur. Urfalı Mateos' a göre bu şehir Garin mıntıkasında Fırat'ın kaynağına yakın bir yerde bulunuyordu. Belazurî. bölgeye hakim olan Ermenyakos' un ölümü üzerine yerine geçen Kali adlı karısı tarafından kurulduğu için Araplarda Kalikala (Kali' nin ihsanı) adını vermişlerdir. Belazuri Kalîkala' yı dördüncü Ermeniyye şehirleri arasında sayar ve Ermeniyye şehirlerinden biri olarak kabul eder. X. asır İslam coğrafyacıları Kalikala şehri hakkında bize malumat vererek, doğuda ev eşyasının en önemlisi sayılan Kali (halı)nın burada yapıldığım ve adını bu şehirden almış olduğunu kaydetmektedirler. Hudud alalam' ın yazarı bu şehrin müstahkem bir kalesi bulunduğunu ve her taraftan gelen gazilerin burayı nöbet tutarak koruduklarım Ve şehirde tüccarların çok olduğunu bildirmektedir. Bugünkü Erzurum adı ise, Erzen' in Selçuklular tarafından fethedilmesi üzerine ahalisinin Theodosiopolis' e (Kalikala=Karin) göç etmelerine müteakip bu şehre Erzen ve Türk hâkimiyetinin ilk safhalarında bu adın sonuna, Meyyafarikin (Silvan) ile Siirt arasındaki Erzen' den ayırmak ve Anadolu'ya ait olduğunu belirtmek üzere Rum kelimesi ilave edilerek, Erzen al-Rum denilmesinden kaynaklanmıştır. Selçuklular tarafından Erzurum'da basılmış paraların üzerinde şehrin adı Arzan al-Rum şeklinde yazılmıştır.
                    <br />
                    <br />
                    <h5>Tarih Öncesi Çağlar</h5>

                    <br />

                    Erzurum ve çevresi özellikle son Kalkolitik ve Eski Tunç çağından itibaren yoğun iskana ve siyasi olaylara tanık olmuştur. Bunun sebebi en eski çağlardan beri önemli ticari ve askeri yolların kavşak noktasında yer almaşı, zengin akarsu ağım bünyesinde bulundurması ve doğal savunma zeminine sahip olmasıdır. Çevredeki sert iklim şartlarına rağmen.dağ silsileleri ve akarsu boylarındaki verimli ovalar tarıma ve bilhassa hayvancılığa uygun bir ortam oluşturmuştur. Karaz, Pulur ve Güzelova kazılarının tanıklığında, yaklaşık altı bin yıldan beri çevredeki yaşama biçiminin devam ettiği söylenebilir. Bölgede M.Ö. IV. binden itibaren çok kuvvetli bir kültür birliğinin olduğu da ortaya çıkmıştır.
                    <br />
                    <br />


                    <h5>MUSTAFA KEMAL PAŞA ERZURUM'DA</h5>
                    <br />

                    İstanbul Hükümeti, İtilaf Devletleri'nin baskıları sonucu, Anadolu'da asayişi sağlamak amacıyla ordu müfettişlikleri teşkil etli. Bu tasarı gereğince. Doğu Anadolu' da ki 9. Ordu Müfettişliğine Mustafa Kemal Paşa tayin edildi. Mustafa Kemal Paşa' ya verilen talimata göre, Trabzon, Erzurum, Sivas, Van Vilayetleriyle Erzincan ve Canik müstakil livalarına gereken emirleri verebileceklerdir. Mustafa Kemal Paşa' ya verilen bu geniş talimattan da anlaşılacağı üzere, O' nun görevi yalnızca Samsun ve havalisindeki asayişsizliğe son vermenin ötesinde idi. Anadolu' ya ayak basar basmaz yapmaya başladığı işlerde bunu ortaya koymaktadır.
                    <br />

                    Mustafa Kemal Paşa 3 Temmuz 1919'da Erzurum'a geldi, ilk karşılama merasimi Erzurum'un batısında on yedi kilometre uzaklıktaki Ilıca' da yapıldı.
                    <br />

                    Mustafa Kemal Paşa Erzurum'a gelişinin ertesi günü 4 Temmuz'da Erzurum Müdafaa-i Hukuk Cemiyeti'ni ziyaret etti.
                    <br />

                    Mustafa Kemal Paşa, 5 Temmuz 1919'da yakın arkadaşları ile bir toplantı yaptı. Toplantı-ya Karabekir Paşa, Rauf Bey, Eski Vali Münir, Süreyya, Ordu Müfettişliği Kurmay Başkanı Kazım, Kurmay Binbaşı Hüsrev, Binbaşı Refik, M.Müfit Beyler katılmışlardı. Toplantıda bulunanlar, Mustafa Kemal Paşa' ya sonuna kadar yardım edeceklerine, onu lider olarak kabul ettiklerine dair söz verdiler.
                    <br />
                    <br />

                    <h5>Erzurum Meselesi</h5>
                    <br />

                    1.Dünya Savaşında Erzurum, istilacı Çarlık Rus Ordusunun ilk hedefi üzerindeydi. Osmanlı Ordularının hezimeti üzerine, önlerinde ciddi bir engel görmeyen Rus Ordusu, General Yudeniç'in komutasında Erzurum'a doğru ilerledi. Erzurum 16 Şubat 1916 da Ruslar tarafından işgal edildi. 1917 yılında Rusya'da Çarlık rejimi yıkılmış, Bolşevikler ülkede duruma el koymuşlardır. Rusya'da bu yönetim değişikliği üzerine Ruslar, işgal ettikleri Doğu Anadolu Bölgesini boşaltarak ülkelerine dönmeye başlamışlardır. Ancak Doğu Anadolu'da Ermenistan hayaliyle yaşayan Ermeniler süratle silahlanarak, Erzurum ve çevresinde "soykırıma" giriştiler. Erzurum Rus II. Topçu Kale Komutanı olan Twerdo Khlebov, Ermenilerin bu kanlı hareketlerine sadece seyirci kaldı.
                    Erzurum ve çevresinde Türklere uygulanan "soykırımı" Taşnak Generali Antranik yönetmiştir. 2 Mart 1918 tarihinde Erzurum Merkez Kumandanlığına tayin olunan General Antranik Alaca, Tepeköy. Ilıca, Yeşil yayla katliamlarında aktif rol oynamış, binlerce insanın hayatına acımasızca son verdirmiştir. Ayrıca Erzurum ve çevresindeki Türk "soykırımın" da Fransız asıllı Ermeni Albayı Morel, Divan-ı Harp üyesi Sohumyan, Muratyan, Dr. Azeryef ve Dr. Karakin Pastırmacıyan görev almışlardı.
                    1918 yılının Şubat ve Mart aylarında bu tarihi şehir insanıyla, medeniyetiyle, kültür varlıklarıyla ortadan kaldırılmaya çalışılıyordu. Şehrin her mahalle ve şose yollarında, çarşılarda Ermeni çete noktaları kurulmuştu. Yol yaptırmak bahanesiyle toplu halde götürülen insanlar Kars kapı ve Yanık dere bölgelerinde, senelerce ekmeğini bölüştüğü Ermeni canileri tarafından şehit edilmişlerdir.
                    Sonra Erzurum Garnizonlarında bulunan Ermeni askerleri evlere saldırarak yağma, öldürme, ırza geçme gibi muameleleri yapmaya başlamışlardır. Erzurum'a giren Türk birlikleri şehir içinde 2127 şehit defnetmişler, ayrıca Kars kapıda da 250 ceset bulmuşlardır. Türk-Ermeni ilişkilerini tarihi perspektif içerisinde incelediğimizde, bölgede Türk insanıyla birlikte yaşamış, kapı komşusu olmuş Ermeni'nin ihaneti açıkça ortadadır. Milleti Sadıka diye adlandırılan Ermenilerin Aşkale, Tazegül, Cinis, Alaca, Ilıca, Tepeköy, Erzurum-Merkezde; Yanık dere, Kars kapı, Ezirmikli Osman Ağa ve Mürsel Paşa konakları, Yakutiye Kışla hamamı, Yeşil yayla, Hasankale-Tımar, Köprüköy, Horasan da yaptıkları insanlık dışı katliamlar sonunda Türk Milleti'nin hafızasında "Yerli Gavur" olarak unutulmayacak bir iz bırakmıştır.
                    Kazım Karabekir Paşa, 12 Mart sabahım şöyle dile getiriyordu: "Erzurum'da halk göz yaşları içinde kimi babasını, kimi karışım yakılmış yada süngülenmiş buluyor, saçlarım yoluyordu, sokaklarda canlılıktan bir iz bile kalmamıştı. Yerlerde çocuk, kadın ve yaşlılar kanlar içinde yatıyordu." Ermenilerin yalnız son gece (11-12 Mart 1918) 3000 Müslüman Türk'ü öldürdüklerini, Erzurum'daki Rus Yarbayı Twerdo- Khelebof anılarında ifade etmiştir. "Demiryolu istasyonun da sanki bir mezarlık ölülerini dışarıya çıkarmıştı. Cenazeler arasından geçerek feci duruma gözlerimizle şahit olduk. Bilhassa Tahtacılar semtinde karşılıklı yer alan Osman Ağa ve Mürsel Paşa konaklarına doldurulup yakılan ve katledilen Erzurumlular insanı titrediyordu."
                    Erzurum'da resmi belgelere göre 9563 yerli Türk ahali Taşnak Ermeni çeteleri tarafından şehit edilmiştir.
                    12 Mart 1918 günü Türkün kalbi olan Erzurum'un esaretten hürriyete, ölümden hayata kavuştuğu bir gündür. 12 Mart 1918 de Türk Hükümeti, Doğuda ki güzel toprakları, yüksek dağları mert kanıyla sulayarak, düşmana göğüs geren Erzurum'u karanlık bir günden kurtardığının yıl dönümüdür.
                    12 Mart 1918 tarihi Erzurum kalesinin beklediği kutsal sabahtır. 84 yıl önce bir 12 Martta zamanın saygısı altında kalan, hatırladıkça kanayan bir yara içimizi sızlatır, sevincimizi göz yaşlarımızın ıslaklığı, mutluluğu kederimizin hüznü, Hürriyetimizde kanımızın pahası, yaşamımızda Türk olmanın gururu, bayrağımızda varlığımızın manası vardır ve saklıdır, işte 12 Mart kutlu günümüzün bizlere hatırlattıkları bu duygu ve fikirlerdir.
                    <br />
                    <br />
                    <h5> ERZURUM KONGRESİ - (23 TEMMUZ - 7AĞUSTOS 1919)</h5>

                    <br />

                    Erzurum Kongresi, I. Dünya Savaşı’nın uğursuzluğunu acımasız maddeleri ile tamamlayan Mondros Mütarekesi’nin (30 Ekim 1918) uygulanmaya başlandığı tarihlere rastlamaktadır. Osmanlı İmparatorluğu’nun imzalamak zorunda kaldığı mütarekenin 24. Maddesi: “Vilâyat-ı Sitte’de karışıklık çıktığı takdirde, bu vilâyetlerin herhangi bir kısmının işgal hakkını İtilâf Devletleri muhafaza ederler” şeklinde düzenlenmişti. Söz konusu vilâyetler: Erzurum, Van, Bitlis, Elazığ, Diyarbakır ve Sivas vilâyetleridir ve mütareke belgesinin İngilizce olan metninde bu vilâyetler “Ermeni Vilâyetleri” olarak ifade edilmiştir. Bu durum, öteden beri varlığı hissedilen Ermeni tehlikesini tekrar gündeme getirmiş ve meseleye yönelik duyarlılık ilk olarak kendini, İstanbul’da, “Vilâyat-ı Şarkıyye Müdafaa-i Hukuk-ı Milliye Cemiyeti”nin kurulmasıyla göstermiştir.(Aralık 1918) Daha sonra Mart 1919’da Erzurum’da bu cemiyetin bir şubesinin açılmasıyla beraber bölgedeki teşkilatlanmanın öncülüğü yapılmış, bundan sonra Erzurum, Milli Mücadele’nin temellerinin atıldığı önemli merkezlerden biri haline gelmiştir. Şehrin Ermenilere verileceği söylentileri bir panik havası yaratmış, bu ortamda halk cemiyete sıkı sıkıya bağlanıp bölgenin ve vatanın kurtuluşu için çare yolları aramaya başlamıştır. Bu süreç içerisinde toplanan Erzurum Kongresi, savaşlar, antlaşmalar ve mücadelelerle uzayıp gelen tarih zincirinin önemli bir halkasını oluşturmuştur.
                    Kongre, Erzurum Müdafaa-i Hukuk-ı Milliye ile Trabzon Müdafaa-i Hukuk-ı Milliye Cemiyetlerinin ortak girişimleriyle 23 Temmuz (Çarşamba) – 7 Ağustos (Perşembe) 1919 tarihleri arasında Nutuk’ta belirtildiği gibi çalışmalarını 14 günde tamamlamıştır. Mustafa Kemal ve Rauf Beyler’in Kongreye Erzurum (merkez) delegeleri olarak katılabilmeleri için Emekli Binbaşı Kazım (Yurdalan) ve Müdafaa-i Hukuk Cemiyeti’nin kurucularından Cevat (Dursunoğlu) Bey, delegelik haklarından vazgeçmişlerdir.
                    Kongrenin ilk günü yapılan oylama ile Mustafa Kemal Paşa, Kongre başkanı seçilmiştir. Mustafa Kemal yaptığı açılış konuşmasında, Osmanlı Devleti’nin içinde bulunduğu durumu, dünyanın çeşitli yerlerinde milli bağımsızlık uğruna yapılan mücadeleleri anlatarak bağımsız, milli bir iradenin Anadolu’dan çıkacağını ve bunun millete dayanması gerektiğini belirtmiştir. Yoğun çalışmalardan sonra 7 Ağustos’a kadar süren kongre, aynı gün Heyet-i Temsiliye seçimlerini yapmış, 9 kişilik heyetin başına Mustafa Kemal getirilmiştir. Böylece milli mücadelenin ilk siyasi kuruluşu da oluşturulmuştur.
                    Kongre sonunda yayınlanan beyannamenin giriş kısmında: Aydın Vilâyetinde Yunanlıların, Kafkasya’da Ermenilerin, Karadeniz’de Rumların Müslüman ahaliye yaptığı zulümlerden, milleti parçalanma tehlikesi karşısında gören Doğu Anadolu halkının kurduğu cemiyetler vasıtasıyla Erzurum Kongresi’ne katıldığından ve kongrenin yayınlanan kararları aldığından bahsedilmiştir. Kongrede alınan kararlar şu şekilde özetlenebilir: Milli sınırlar içinde vatan bir bütündür ayrılık kabul edilemez; İşgal ve müdahaleler sonucu Osmanlı Devletinin dağılması halinde millet tek vücut olarak yurdunu savunacaktır; Vatanın bağımsızlığını korumaya İstanbul Hükümeti’nin gücü yetmediği takdirde, geçici bir hükümet kurulacaktır. Bu hükümet milli kongre tarafından seçilecektir; Kongre toplantıda değilse bunu Heyet-i Temsiliye üstlenecektir; Kuvâ-yı Miliyeyi etken ve milli iradeyi hakim kılmak esastır; Hıristiyan azınlıklara siyasî hakimiyet ve sosyal dengemizi bozacak ayrıcalıklar verilemez; Manda ve himaye kabul edilemez; Milli Meclis’in hemen toplanmasını ve hükümet işlerinin meclis denetiminde yürütülmesini sağlamak için çalışılacaktır.
                    Erzurum Kongresi’nin amacı, sadece doğu bölgesinin bütünlüğü değil, 30 Ekim 1918’deki sınırıyla “Vatanın bütünlüğü” idi. Bağımsızlık ise, yine bütün Türk milletinindi. Bu gayenin ilk adımı Erzurum Kongresi’nde atıldı.
                    Kongrenin kabul ettiği kararları, belirlediği hedefleri, çizdiği stratejiyi iyi tahlil etmek gerekir. Kongrenin benimsediği hedefler, Türk Milli mücadelesinin de gerçekleştirmeye çalıştığı milli hedeflerdir. Milli Mücadele hareketinin siyasal temeli olan “hukuk-ı milliye” ilk olarak bu kongrede dile getirilmiştir. Kongrenin kabul ettiği kararlar ise milli bağımsızlık savaşımızın programı olarak ele alınmış, belirlediği hedefler gerçekleştirilmiştir. 28 Ocak 1920’de Son Osmanlı Mebusan Meclisi’nde Misâk-ı Milli adıyla kabul edilip, 17 Şubat 1920’de bütün dünyaya ilân edilen programın esasları Erzurum’da, bu kongrede belirlenmiştir. Kongre, temsil ettiği fikir ve prensiplerle, sağladığı yetkiler bakımından Milli Mücadele hareketinin tarihi bir hareket ve çıkış noktasıdır. Mustafa Kemal Atatürk’ün ifadesiyle; “Tarih şüphesiz bu kongreyi ender ve büyük bir eser sayarak bağrına basacaktır.”
                </span>
            </div>
        </div>
    )
}

export default ErzurumTarihi
