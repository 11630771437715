import React, { useState, useMemo } from "react";
import Header from "./components/Header";
import { useParams, useLocation, Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import ContentLoader, { Facebook } from 'react-content-loader'
import './Detay.css'
let PageSize = 12;
function Detay() {
  const query = useLocation();
  const [veri, setveri] = useState([]);
  const [veriSearch, setveriSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(1);
  const [pageSlice, setPageSlice] = useState([0, 12]);
  const [pageLength, setPageLength] = useState(null)
  React.useEffect(() => {
    fetch(
      `https://rota.erzurum.bel.tr/webservisi/TuristikYerleriGetir?kullaniciadi=rotaerzurum&sifre=hs5352CXXv&tipid=${query.state.id}&turistikid=0`
    ).then((res) => res.json())
      .then((res) => {
        setveri(res)
        setveriSearch(res)
        setPageLength(Math.ceil(res.length / 12))
        setLoading(false)
      });
  }, [])

  const Pagination = () => {
    var array = [];
    for (var i = 0; i < pageLength; i++) {
      array.push(i + 1);
    }
    return <>
      {array.map((data, index) => {
        return (
          <>

            <a onClick={() => changePagination(index)} className={index === pagination - 1 ? " active" : ""} style={{ width: 30, height: 30, cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{data}</a>


          </>
        )
      })}
    </>

  }

  const changePagination = (data) => {
    var newPagi = data;
    setPageSlice([12 * newPagi, 12 * newPagi + 12])
    setPagination(newPagi + 1)
  }
  const ileri = () => {
    var newPagi = pagination;
    if (newPagi < pageLength) {
      if (newPagi === 1) {
        setPageSlice([12, 24])
      } else {
        setPageSlice([pageSlice[0] + 12, pageSlice[1] + 12])
      }
      newPagi = newPagi + 1;
      setPagination(newPagi)
    }

  }

  const geri = () => {
    var newPagi = pagination;
    if (newPagi > 1) {
      if (newPagi === 1) {
        setPageSlice([0, 12])
      } else {
        setPageSlice([pageSlice[0] - 12, pageSlice[1] - 12])
      }
      newPagi = newPagi - 1;
      setPagination(newPagi)
    }
  }
  const ara = (e) => {
    var dizi = [];
    if (e.target.value !== ''){
      dizi = veri.filter((x) => x.turistikyeradi.toLowerCase().includes(e.target.value.toLowerCase()));
      // veri.forEach(x => console.log(x.turistikyeradi))
    }
    else {
      dizi = veri
    }
    setveriSearch(dizi)
    setPageLength(Math.ceil(dizi.length / 12))
  }
  return (
    <>
      <Header bgColor={true} yerlesim={true} />
      <div className="container text" style={{ marginTop: 150 }}>

        <div className="row align-items-center justify-content-between">
          <div className="col-md-4">
            <h3 style={{ color: "black", fontWeight: "bold" }}>
              {query.state.tipadi}
            </h3>
          </div>
          <div className="col-md-4">
            <span
              class="badge bg-success text-white"
              style={{ fontSize: 14, float: 'right', height: 35, marginLeft: 10, justifyContent: 'center', alignItems: 'center', display: 'flex' }}
            >
              {!loading ? veri.length + " Adet " + query.state.tipadi : null}
            </span>
            <input onChange={(e) => ara(e)} className="form-control" placeholder="Ara .." style={{ fontSize: 14, float: 'right', width: 200 }} />

          </div>
        </div>
        <hr />
        <div className="row mt-3 ">
          {
            loading ?
              <>
                <ContentLoader viewBox="0 0 380 70">
                  <rect x="10" y="0" rx="5" ry="5" width="70" height="70" />
                  <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                  <rect x="190" y="0" rx="5" ry="5" width="70" height="70" />
                  <rect x="280" y="0" rx="5" ry="5" width="70" height="70" />

                </ContentLoader>
                <ContentLoader viewBox="0 0 380 70">
                  <rect x="10" y="10" rx="5" ry="5" width="70" height="70" />
                  <rect x="100" y="10" rx="5" ry="5" width="70" height="70" />
                  <rect x="190" y="10" rx="5" ry="5" width="70" height="70" />
                  <rect x="280" y="10" rx="5" ry="5" width="70" height="70" />

                </ContentLoader>
              </>
              : veriSearch.slice(pageSlice[0], pageSlice[1]).map((data, index) => {
                return (
                  <>
                    <Link
                      className={` bg-white col-sm-3 mt-3`}
                      style={{ borderRadius: 10, cursor: "pointer" }}
                      key={index}
                      to={{ pathname: `/detay/${data.id}`, state: { data: data } }}
                    >
                      <div
                        className="border shadow p-2 "
                        style={{
                          overflow: "hidden",
                          borderRadius: 10,
                          height: 150,
                          position: "relative",
                          backgroundSize: "100% 160%",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: data.gorseller
                            ? `url(https://rota.erzurum.bel.tr${data.gorseller[0]})`
                            : `url(https://cdn.yemek.com/mncrop/940/625/uploads/2021/04/patlicanli-pilav-yemekcom.jpg)`,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            backgroundColor: "rgb(0,0,0,.6)",
                          }}
                        >
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 55,
                            }}
                          >
                            <p
                              className=" text-center text-white "
                              style={{ fontWeight: "bold", fontSize: 17 }}
                            >
                              {data.turistikyeradi}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })
          }
        </div>
        {
          pageLength > 1 ?
            <div className="row align-items-center  justify-content-center pagination" style={{ marginTop: 15 }}>
              <a style={{ marginRight: 20, cursor: 'pointer', width: 65 }} onClick={() => geri()}>Geri</a>
              {Pagination()}
              <a style={{ marginLeft: 20, cursor: 'pointer', width: 65 }} onClick={() => ileri()}>İleri</a>
            </div>
            : null
        }

      </div>
    </>
  );
}

export default Detay;
