import React from "react";
import "../App.css";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import InfiniteScroll from 'react-infinite-scroller';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  LayersControl,
  LayerGroup,
  Circle,
  Rectangle,
  FeatureGroup,
} from "react-leaflet";
import parse from "html-react-parser";
import "leaflet/dist/leaflet.css";
import {
  CgMenuLeft,
  AiOutlineCloseCircle,
  GiVerticalBanner,
  BsFillCaretRightFill,
} from "react-icons/all";
import icon from "leaflet/dist/images/marker-icon.png";
import logo from "../assets/images/LOGO.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import turistik from "./webservice/TuristikYerlerDetay";
import $, { data } from "jquery";
import "./Map.css";
import "./Map.scss";
import RoutineMachine from "../views/RoutineMachine";
import { usePosition } from './components/usePosition';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Link, useLocation } from "react-router-dom";
import ContentLoader, { Facebook } from 'react-content-loader';
import KUDEBLOGO from '../assets/images/KUDEB LOGO 2021.png'
import BilgiIslem from '../assets/images/bilgiislem.png'
import LOGOROTA from '../assets/images/Rota_Erzurum_Logo_1.png';
import Logo from "../assets/images/LOGO.png";
import Header from "./components/Header";
const ACCESS_TOKEN = "pk.eyJ1IjoiYXppemt1cnQiLCJhIjoiY2szZmV2MTE5MDN1eTNvcnpyNWpuNmdoOSJ9.eR4d2tvxd1BFhqzI3gFaXQ";
const height = window.screen.height;
console.log(height)
function App({ kategori, route }) {
  const query = useLocation();
  const [turistikid, setTuristikId] = React.useState(query.state ? query.state.data.turistikid : null)
  const [tipid, setTipid] = React.useState(query.state ? query.state.data.tipid : null)
  const [gelenKonum, setGelenKonum] = React.useState(query.state ? [query.state.data.xkonum, query.state.data.ykonum] : [0, 0])
  const [gelenBilgiler, setGelenBilgiler] = React.useState(query.state ? '' : null)
  const [secilenKategori, setSecilenKategori] = React.useState("tümü");
  const [secilenKategoriler, setSecilenKategoriler] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const itemsPerPage = 20;
  const [records, setrecords] = React.useState(itemsPerPage);
  const [hasMoreItems, sethasMoreItems] = React.useState(true);
  const { latitude, longitude, error } = usePosition();
  function GetIcon(tipid) {
    if (tipid !== "bos")
      for (var i = 0; i < kategori.length; i++) {
        if (tipid === kategori[i].id) {
          var image = kategori[i].ikon;
          return L.divIcon({
            className: "map-bg",
            html: `<img class="icon__map img-fluid" src="https://rota.erzurum.bel.tr/${image}"/>`,
          });
        }
      }
    else {
      return L.divIcon({
        className: "map-bg-bos",
        html: `<img class="icon__map__bos img-fluid" src="https://rota.erzurum.bel.tr/${image}"/>`,
      });
    }
  }
  const [isLeftBar, setIsLeftBar] = React.useState(false);
  const [tumYerler, setTumYerler] = React.useState([]);
  const center = [39.90585927565791, 41.26645346462497];
  React.useEffect(() => {
    // window.document.getElementById('leaflet-container').style.height = height-100;
    turistik(0, 0).then((data) => {

      setTumYerler(data);
      setLoading(false)
    });
  }, []);
  const openLeft = () => {
    var id = document.getElementById("leftBar");
    if (isLeftBar) {
      //KAPALI
      $(".leftBar").removeClass("animate__bounceInLeft");
      $(".leftBar").addClass("animate__bounceOutLeft");
      // id.style = "visibility: hidden;opacity: 0;";
    } else {
      //AÇIK
      $(".leftBar").removeClass("animate__bounceOutLeft");
      $(".leftBar").addClass("animate__bounceInLeft");
      id.style = "visibility: visible;opacity: 1;";
    }
    setIsLeftBar(!isLeftBar);
  };
  const kategoriSec = (secilen) => {
    setSecilenKategori(secilen);
    if (!isLeftBar) openLeft();
    if (secilen === "tümü")
      turistik(0, 0).then((data) => {
        console.log(data);
        setSecilenKategoriler(data);
      });
    else {
      turistik(secilen, 0).then((data) => {
        console.log(data);
        setSecilenKategoriler(data);
      });
    }
  };

  return (
    <><Header bgColor={true} yerlesim={false} />




      <div className="map-container" style={{ height: height - 211 }}>

        <div className="left__card_top animate__animated animate__zoomIn animate__repeat" style={{ marginTop: 100 }}>
          <select
            name="cars"
            id="cars"
            className="menu "
            onChange={(e) => kategoriSec(e.target.value)}
          >
            <option value={"tümü"} selected>Tümü</option>
            {kategori.map((data, index) => {
              return (
                <option key={index} value={data.id}>
                  {data.tipadi}
                </option>
              );
            })}
          </select>
          <div className="menu_right ">
            {isLeftBar ? (
              <AiOutlineCloseCircle
                onClick={() => openLeft()}
                size={25}
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            ) : (
              <CgMenuLeft
                onClick={() => openLeft()}
                size={25}
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            )}
          </div>
        </div>
        <div
          className="leftBar animate__animated animate__repeat p-3"
          id="leftBar"
        >
          {secilenKategoriler.map((data, index) => {
            return (
              <>
                <div className="container menu_visible" style={{ cursor: 'pointer' }} key={index} onClick={() => { setTipid(data.tipid); setTuristikId(data.turistikid); }}>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      {data.gorseller ? <img
                        src={`https://rota.erzurum.bel.tr${data.gorseller[0]}`}
                        className="img-fluid"
                        alt="resim"
                      /> : null}

                    </div>
                    <div className="col-md-4">
                      <span>{data.turistikyeradi.trim()}</span>
                    </div>
                    <div className="col-md-4">
                      <span className="float-right mt-2">
                        <BsFillCaretRightFill />
                      </span>
                    </div>
                  </div>
                  <hr />

                </div>
              </>
            );
          })}
        </div>
        {loading ? <> <ContentLoader viewBox="0 0 380 970">
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="800" />
        </ContentLoader>
        </> :
          <div class="details-on-map show-map" style={{ height: height - 211 }}>
            <div class="paper-map">
              <div class="map-side" style={{ height: height - 210, display: "flex", alignItems: "center", justifyContent: "center" }}></div>
              <div class="map-side" style={{ height: height - 210, display: "flex", alignItems: "center", justifyContent: "center" }}><img src={Logo} width={300} /></div>
              <div class="map-side" style={{ height: height - 210, display: "flex", alignItems: "center", justifyContent: "center" }}><img src={LOGOROTA} width={200} /></div>
              <div class="map-side" style={{ height: height - 210, display: "flex", alignItems: "center", justifyContent: "center" }}></div>
            </div>
            <MapContainer center={center} zoom={8} zoomControl={false} maxZoom={18} minZoom={8} style={{ height: height - 211, width: '100%', overflow: 'hidden' }}>
              {tipid && turistikid ?
                <Marker
                  position={[tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid).xkonum, tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid).ykonum]}
                  icon={
                    GetIcon(tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid && x.kod.trim() == "KM").tipid ? tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid && x.kod.trim() == "KM").tipid : "bos")

                  }
                >
                  <Popup>
                    <h4 className="mt-2">{tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid).turistikyeradi}</h4>{" "}
                    <span>{tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid).genelbilgiveri ? parse(tumYerler.find(x => x.tipid == tipid && x.turistikid == turistikid).genelbilgiveri.slice(0, 500)) : null}</span>
                    {/* <Link to={{ pathname: `/detay/${data.id}`, state: { data: data } }} >Devamını Oku</Link> */}
                  </Popup>
                </Marker>


                : null}
              <MarkerClusterGroup maxClusterRadius={30} showCoverageOnHover={false}>
                {tumYerler.map((data, index) => {
                  if (!tipid && !turistikid) {
                    if (secilenKategori === "tümü")
                      return (
                        <>
                          <Marker
                            position={
                              data.kod.trim() === "KM"
                                ? data.xkonum && data.ykonum
                                  ? [data.xkonum, data.ykonum]
                                  : [0, 0]
                                : [0, 0]
                            }
                            icon={
                              data.kod.trim() === "KM"
                                ? GetIcon(data.tipid)
                                : GetIcon("bos")
                            }
                            index={index}
                          >
                            <Popup>
                              <h4 className="mt-2">{data.turistikyeradi}</h4>{" "}
                              <span>{data.genelbilgiveri ? parse(data.genelbilgiveri.slice(0, 500)) : null}</span>
                              <Link to={{ pathname: `/detay/${data.id}`, state: { data: data } }} >Devamını Oku</Link>
                            </Popup>
                          </Marker>
                        </>
                      );
                    else {

                      if (Number(secilenKategori) === Number(data.tipid)) {
                        return (
                          <>
                            <Marker
                              position={
                                data.kod.trim() === "KM"
                                  ? data.xkonum && data.ykonum
                                    ? [data.xkonum, data.ykonum]
                                    : [0, 0]
                                  : [0, 0]
                              }
                              icon={
                                data.kod.trim() === "KM"
                                  ? GetIcon(data.tipid)
                                  : GetIcon("bos")
                              }
                              index={index}
                            >
                              <Popup>
                                <h4 className="mt-2">{data.turistikyeradi}</h4>{" "}
                                <span>{data.genelbilgiveri ? parse(data.genelbilgiveri.slice(0, 500)) : null}</span>
                                <Link to={{ pathname: `/detay/${data.id}`, state: { data: data } }} >Devamını Oku</Link>
                              </Popup>

                            </Marker>
                          </>
                        );
                      }
                    }



                  } else {
                    // if (data.tipid === tipid && data.turistikid === turistikid)
                    //   return (
                    //     <>
                    //       <Marker
                    //         position={[data.xkonum, data.ykonum]}
                    //         icon={
                    //           data.kod.trim() === "KM"
                    //             ? GetIcon(tipid)
                    //             : GetIcon("bos")
                    //         }
                    //         index={index}

                    //       >
                    //         <Popup>
                    //           <h4 className="mt-2">{data.turistikyeradi}</h4>{" "}
                    //           <span>{data.genelbilgiveri ? parse(data.genelbilgiveri.slice(0, 500)) : null}</span>
                    //           <Link to={{ pathname: `/detay/${data.id}`, state: { data: data } }} >Devamını Oku</Link>
                    //         </Popup>
                    //       </Marker>


                    //     </>
                    //   )
                    // else return null
                  }
                })}
              </MarkerClusterGroup>
              <LayersControl position="topright">
                <LayersControl.BaseLayer name="Renkli Mod" checked>
                  <TileLayer
                    attribution='&copy; <a href="http://www.erzurum.bel.tr">Erzurum Büyükşehir Belediyesi</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Karanlık Mod" >
                  <TileLayer
                    attribution='&copy; <a href="http://www.erzurum.bel.tr">Erzurum Büyükşehir Belediyesi</a>'
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Uydu Modu" >
                  <TileLayer
                    attribution='Erzurum Büyükşehir Belediyesi'
                    url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`}
                  />
                </LayersControl.BaseLayer>

              </LayersControl>
            </MapContainer>
          </div>}

      </div>
    </>
  );
}

export default App;
