import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { BoxLoading } from 'react-loadingg';
import Map from "./views/Map";
import Home from "./views/Home";
import KategoriGetir from "./views/webservice/KategoriGetir";
import Detay from "./views/Detay";
import Detay_ from "./views/Detay_";
import TumKategori from "./views/TumKategori";
import Kudeb from "./views/Kudeb";
import ErzurumTarihi from "./views/ErzurumTarihi";
import Rota from "./views/Rota";
import Footer from "./views/components/Footer";
import Mevzuat from "./views/Mevzuat";
import SitAlanlari from "./views/SitAlanlari";
import BakimOnarim from "./views/BakimOnarim";
import QRPage from "./views/QRPage";
import CanliKameralar from "./views/CanliKameralar";
import RotaDetay from "./views/RotaDetay";
import SanalTurlar from "./views/SanalTurlar";
export default function App() {
  const [kategoriler, setKategoriler] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    KategoriGetir.then((res) => {
      setKategoriler(res);
      setLoading(false)
    });
  }, []);
  if (loading) {
    // <div className="loadingCss">
    //     <img src={Logo} className="img-fluid"/>

    // </div>
    return <><BoxLoading color={'rgb(15, 52, 71)'} /></>;
  }
  return (
    <Router>
      <Switch>
        <Route path="/" exact >
          <Home kategori={kategoriler} />
        </Route>
        <Route path="/index.html" exact >
          <Home kategori={kategoriler} />
        </Route>
        <Route path="/map" exact>
          <Map kategori={kategoriler} />
        </Route>
        <Route path="/canlikameralar" exact>
          <CanliKameralar kategori={kategoriler} />
          <Footer />
        </Route>
        <Route path="/tum" exact>
          <TumKategori kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/kudeb" exact >
          <Kudeb kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/mevzuat" exact >
          <Mevzuat kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/bakimonarim" exact >
          <BakimOnarim kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/sitalanlari" exact >
          <SitAlanlari kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/rota" exact >
          <Rota kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/erzurumtarihi" exact >
          <ErzurumTarihi kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/yerbilgisi" exact >
          <QRPage kategori={kategoriler} />
          <Footer />

        </Route>
        <Route path="/kategori/:id" exact>
          <Detay />
          <Footer />

        </Route>
        <Route path="/detay/:id" exact>
          <Detay_ />
          <Footer />

        </Route>
        <Route path="/rotadetay" exact>
          <RotaDetay />
          <Footer />

        </Route>
        <Route path="/sanalturlar" exact>
          <SanalTurlar />
          <Footer />

        </Route>
      </Switch>
    </Router>
  );
}
