import React, { useState } from "react";
import Header from "./components/Header";
import { Link, useLocation } from "react-router-dom";
import turistik from "../views/webservice/TuristikYerlerDetay";
import parse from "html-react-parser";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Modal as ReactModal } from "react-bootstrap";
import { BiMap } from "react-icons/bi";
import RoutineMachine from "../views/RoutineMachine";
import ContentLoader, { Facebook } from 'react-content-loader'
import startLocationImage from '../assets/images/location.png'
import endLocationImage from '../assets/images/location2.png'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  LayersControl,
  LayerGroup,
  Circle,
  Rectangle,
  FeatureGroup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import './Detay_.css'
import { usePosition } from './components/usePosition';
import 'react-slideshow-image/dist/styles.css'
import { Zoom } from 'react-slideshow-image';
function Detay_() {
  const query = useLocation();
  const { latitude, longitude, error } = usePosition();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [urunDetayi, setUrunDetayi] = React.useState([]);
  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  React.useEffect(() => {
    turistik(query.state.data.tipid, query.state.data.turistikid).then(
      (res) => {
        setUrunDetayi(res);
        for (var i = 0; i < res[0].gorseller.length; i++) {
          var gorsel = "https://rota.erzurum.bel.tr" + res[0].gorseller[i];
          setImages((images) => [
            ...images,
            {
              src: gorsel,
              sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
              width: 1,
              height: 1,
            },
          ]);
        }
        setLoading(false)
      }
    );
  }, []);
  const zoomInProperties = {
    indicators: true,
    scale: 1.4,
    duration: 3000,
  }
  return (
    <div>
      <Header bgColor={true} yerlesim={true} />
      {
        loading ?
          <>
            <ContentLoader viewBox="0 0 380 120">
              <rect x="0" y="20" rx="5" ry="5" width="100%" height="120" />


            </ContentLoader>
            <div className="container">
              <ContentLoader viewBox="0 0 380 70">
                <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
                <rect x="0" y="40" rx="5" ry="5" width="100%" height="5" />
                <rect x="0" y="50" rx="5" ry="5" width="86%" height="5" />
                <rect x="0" y="60" rx="5" ry="5" width="60%" height="5" />
              </ContentLoader>
            </div>
          </> : <>
            <div className="dis">
              <Zoom {...zoomInProperties} autoplay={images? images.length===1 ? false:true:null}>
                {images.map((each, index) => (
                  <>
                    <div key={index} style={{ width: "100%" }} className="header_image mt-5">
                      <img style={{ width: "100%", height: 600 ,objectFit:"cover" }} src={each.src} />
                    
                    </div>
                  </>
                ))}
              </Zoom>
              <p className="content">
                        {query.state.data.turistikyeradi}
                      </p>
                <div className="content1">
                      {
                    urunDetayi ? urunDetayi.map((data, index) => {
                      if (data.kod.trim() === "KM")
                        return (
                          <div key={index} style={{ display: 'flex', float: 'right' }}>
                            <Link to={{ pathname: `/map`, state: { data: data } }} className="btn btn-sm btn-primary"><BiMap size={25} />Haritada Görüntüle</Link>
                            <a
                              href={`https://www.google.com/maps/dir/${latitude},${longitude}/${data.xkonum},${data.ykonum}`}
                              target="_blank"
                               className="btn btn-sm btn-danger" style={{marginLeft:20}}><BiMap size={25} />Yol Tarifi Al</a>
                          </div>
                        )
                    }) : null
                  }
                  </div>
            </div>
            <div className="container">
             <p style={{fontSize:25,marginTop:40,fontWeight:'bold'}}>{query.state.data.turistikyeradi}</p>
            </div>
            {urunDetayi ? urunDetayi.map((data, index) => {
              if (data.kod.trim() === "HK") {
              
                var newData = data.genelbilgiveri.replaceAll('src="', 'src="https://rota.erzurum.bel.tr')
                return (
                  <div className="container  " style={{ marginTop: 50, marginBottom: 50 }} key={index}>
                    {parse(newData)}
                  </div>
                );
              }

            }) : null}

            <div className="container ">
              <h4>{query.state.data ? "GALERİ" : null}</h4>
            </div>

            <div className={`container  ${images.length > 1 ? 'w-100' : 'w-25'}`}>

              <Gallery photos={images} onClick={openLightbox} className="animate__animated animate__fadeInUp" />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={images.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>



          </>
      }


    </div>
  );
}

export default Detay_;
