import React from 'react'
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import Logo from "../../assets/images/LOGO.png";
import LOGOROTA from '../../assets/images/Rota_Erzurum_Logo_1.png';
import './Footer.css'
function Footer() {
    return (
        <div class="footer-dark">
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-md-3 item" style={{ display: 'flex', alignItems: 'center' }}>
                            <ul style={{ display: 'flex', alignItems: 'center' }}>
                                <li>
                                    <a href="/index.html">
                                        <img src={Logo} alt="image" style={{ height: 40 }} />
                                    </a>
                                </li>
                                <li style={{ marginLeft: 30 }}>
                                    <a href="/index.html">
                                        <img src={LOGOROTA} alt="image" style={{ height: 44.72, width: 46.1, }} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-3 item">
                            <h3>Linkler</h3>
                            <ul>
                                <li>
                                    <Link to="/erzurumtarihi" className="block">
                                        Erzurum Tarihi
                                    </Link>
                                    <div className="ani"></div>
                                </li>
                                <li>
                                    <Link to="/rota" className="block">
                                        Rota
                                    </Link>
                                    <div className="ani"></div>
                                </li>
                                <li>
                                    <Link to="/sanalturlar" className="block">
                                        Sanal Turlar
                                    </Link>
                                    <div className="ani"></div>
                                </li>
                                <li>
                                    <Link to="/map" className="block">
                                        Haritada Keşfet
                                    </Link>
                                    <div className="ani"></div>
                                </li>
                                <li>
                                    <Link to="/canlikameralar" className="block">
                                        Erzurum'u Seyret
                                    </Link>
                                    <div className="ani"></div>
                                </li>

                            </ul>
                        </div>
                        <div class="col-md-6 item text">
                            <h3>Erzurum Büyükşehir Belediyesi</h3>
                            <p>MuratPaşa Mahallesi Merkezi Yönetim Cad. No:2 Erzurum Büyükşehir Belediye Başkanlığı 25100 Erzurum</p>
                        </div>
                        <div class="col item social">
                            <a href="https://www.facebook.com/erzurumbld" target="_blank">
                                <FaFacebookSquare />
                            </a>
                            <a href="https://www.instagram.com/erzurumbld" target="_blank">
                                <RiInstagramFill />
                            </a>
                            <a href="https://twitter.com/erzurumbld" target="_blank">
                                <FaTwitterSquare />
                            </a>
                        </div>
                    </div>
                    <p class="copyright">Erzurum Büyükşehir Belediyesi <br></br> Bilgi İşlem Daire Başkanlığı © 2022</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer
