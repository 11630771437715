import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { useLocation } from 'react-router-dom'
import Header from './components/Header'
import parse from "html-react-parser";
import LeafletPolyline from 'react-leaflet-polyline'
import L from "leaflet";
import './RotaDetay.css'
import ContentLoader from 'react-content-loader';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { BiMap } from 'react-icons/bi';
function RotaDetay() {
    const query = useLocation();
    const [secilen, setSecilen] = useState([])
    const [secilenBaslik, setSecilenBaslik] = useState("")
    const [rotalar, setRotalar] = useState(null);
    const [activeRota, setActiveRota] = useState(0)
    const [yolTarifi, setYolTarifi] = useState([])
    const [yollar, setYollar] = useState([

    ])
    useEffect(() => {
        query.state.data.detayBilgi.map((data, ilkIndex) => {
            return (
                <>
                    {
                        data.bilgiler.map((veriler, index) => {
                            if (veriler.tip.trim() === "KM") {
                                var kontrol = query.state.data.detayBilgi[ilkIndex + 1]?.bilgiler.filter((e) => e.tip.trim() === "KM")[0];
                                if (kontrol) {
                                    if (query.state.data.detayBilgi[ilkIndex + 1]) {
                                        var dataveriler = {
                                            from_lat: veriler.xkonum,
                                            from_long: veriler.ykonum,
                                            to_lat: kontrol.xkonum,
                                            to_long: kontrol.ykonum,
                                        }
                                        if (ilkIndex === 0) {
                                            setYolTarifi(yolTarifi => [...yolTarifi, `https://www.google.com/maps/dir/${dataveriler.to_lat},${dataveriler.to_long}`])
                                        } else {

                                            setYolTarifi(yolTarifi => [...yolTarifi, `/${dataveriler.to_lat},${dataveriler.to_long}`])

                                        }
                                        setYollar(yollar => [...yollar, dataveriler])


                                    }
                                }
                            }
                        })
                    }
                </>
            )
        })

    }, [])
    function GetIcon(index) {
        return L.divIcon({
            className: "map-bg",
            html: `<div style="position:relative;"><img class="icon__map img-fluid" src="https://rota.erzurum.bel.tr/static/media/location2.png"/><div style="display:flex;align-items:center;justify-content:center;border-radius:100%;position:absolute;top:8px;left:15px;background:white;height:21px;width:21px;"><span style="font-weight:bold;">${index}</span></div></div>`,
        });
    }


    return (
        <div>
            <Header bgColor={true} yerlesim={true} />
            <div className='container' style={{ marginTop: 150 }}>
                <h3 style={{ fontWeight: 'bold' }}>{query.state.data.aciklama}</h3>
                <span>{query.state.data.detay}</span>
                {

                }

                <MapContainer center={[39.903932206965855, 41.27866566164813]} zoom={16} style={{ height: 500, marginTop: 30, borderRadius: 15 }}>
                    <a
                        href={`${yolTarifi.join('')}`}
                        target="_blank"
                        className="btn btn-sm btn-danger float-right haritaButon" style={{ marginLeft: 20 }}><BiMap size={25} />Yol Tarifi Oluştur</a>
                    <TileLayer
                        attribution=''
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        query.state.data.detayBilgi.map((data, ilkIndex) => {

                            return (
                                <>
                                    {
                                        data.bilgiler.map((veriler, index) => {
                                            if (veriler.tip.trim() === "KM") {
                                                return (
                                                    <>
                                                        <Marker position={[veriler.xkonum, veriler.ykonum]}
                                                            icon={
                                                                GetIcon(ilkIndex + 1)
                                                            }
                                                            eventHandlers={{
                                                                click: (e) => {
                                                                    setActiveRota(ilkIndex)
                                                                    setSecilen(query.state.data.detayBilgi[ilkIndex]);
                                                                    setSecilenBaslik(data.id)
                                                                },
                                                            }}>

                                                        </Marker>
                                                        {
                                                            yollar.map(({ from_lat, from_long, to_lat, to_long }) => {
                                                                return <Polyline positions={[
                                                                    [from_lat, from_long], [to_lat, to_long],
                                                                ]} color={'rgb(9 62 80)'} />
                                                            })

                                                        }
                                                    </>
                                                )
                                            }

                                        })
                                    }
                                </>
                            )

                        })
                    }

                </MapContainer>



            </div>
            <div class="container mt-4">
                <div class="vertical-tab " role="tabpanel">
                    <ul class="nav nav-tabs " role="tablist">
                        {
                            query.state.data.detayBilgi.map((data, ilkIndex) => {
                                return (
                                    <>
                                        {
                                            data.bilgiler.map((veriler, index) => {
                                                if (veriler.tip.trim() === "HK") {
                                                    return (
                                                        <li role="presentation" class={ilkIndex === activeRota ? "active" : null} onClick={() => setActiveRota(ilkIndex)}><a aria-controls="home" role="tab" data-toggle="tab"><span className="mr-4">{ilkIndex + 1} - </span>{veriler.adi.toUpperCase()}</a></li>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                )
                            })
                        }

                    </ul>
                    <div class="tab-content tabs" style={{ overflow: 'hidden' }}>
                        <div role="tabpanel" >
                            {
                                query.state.data.detayBilgi.map((data, ilkIndex) => {

                                    return (
                                        <>
                                            {
                                                data.bilgiler.map((veriler, index) => {
                                                    if (veriler.tip.trim() === "HK") {
                                                        var newData = veriler.genelbilgiveri ? veriler.genelbilgiveri.replaceAll('src="', 'src="https://rota.erzurum.bel.tr') : null
                                                        return (
                                                            <div role="tabpanel" style={{ display: activeRota !== ilkIndex ? 'none' : null }}>
                                                                <span style={{ fontWeight: 'bold' }}>{veriler.adi}</span>
                                                                <br></br>
                                                                <br></br>
                                                                <span  >{parse(newData)}</span>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default RotaDetay