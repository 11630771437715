import axios from 'axios';


export default function turistik(tipid,turistikid){
    return axios.get(`${process.env.REACT_APP_WEB_API}/TuristikYerleriGetir?kullaniciadi=rotaerzurum&sifre=hs5352CXXv&tipid=${tipid}&turistikid=${turistikid}`)
    .then((res)=>{
        return res.data
    }).catch((err)=>{
        return err
    })
} 