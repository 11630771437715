import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";

function TumKategori({ kategori }) {
  return (
    <>
      <Header bgColor={true} />
      <div className="container" style={{ marginTop: 150 }}>
        <h3 style={{ fontWeight: "bold" }}>Tüm Kategoriler</h3>
        <div className="row">
          {kategori.map((data, index) => {
            return (
              <Link to={{pathname: `/kategori/${data.id}`, state:{id: data.id, tipadi: data.tipadi}}} className={`col-md-3  `} style={{color:'black'}}>
                <div className="shadow-sm border align-items-center justify-content-center  p-5 mt-3 rounded">
                  <center>
                  <img src={`https://rota.erzurum.bel.tr/${data.ikon}`} alt="image" style={{width:50,height:50}} />
                  <p style={{marginTop:10,fontSize:16}}>{data.tipadi}</p>
                  </center>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TumKategori;
