import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  LayersControl,
  LayerGroup,
  Circle,
  Rectangle,
  FeatureGroup,
} from "react-leaflet";
import L from "leaflet";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
function Rota() {
  const [rotalar, setRotalar] = useState([]);
  const [rotaimage, setRotaImage] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const bootstrap = async () => {
      var res = await fetch('https://rota.erzurum.bel.tr/webservisi/RotaDetayGetir?id=0')
      var response = res.json();
      response.then((resRota) => {
        setRotalar(resRota)
      })
      var res1 = await fetch('https://rota.erzurum.bel.tr/webservisi/rotalarigetir')
      var response1 = res1.json();
      response1.then((resRota) => {
        setRotaImage(resRota)
        console.log(resRota)
      })
      setLoading(false)

    }
    bootstrap()
  }, [])
  return (
    <div>
      <Header bgColor={true} yerlesim={true} />
      {
        loading ?
          <div className="container" style={{ marginTop: 150 }}>

            <ContentLoader viewBox="0 0 380 600">
              <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
              <rect x="0" y="40" rx="5" ry="5" width="90%" height="30" />
            </ContentLoader>

          </div>
          : <div className="container" style={{ marginTop: 150 }}>
            <h3 style={{ fontWeight: 'bold' }}>Rotalar</h3>
            <hr style={{ backgroundColor: '#B5B4B4' }}></hr>
            <div className="row justify-content-between">
              {
                rotalar.map((data, index) => {
                  if(rotaimage[index]){
                    return (
                      <Link className="col-md-6 mt-4 p-4 text-black" style={{ cursor: 'pointer' }} key={index}
                        to={{ pathname: `/rotaDetay`, state: { data: data } }}
                      >
                        {/* {
                          rotaimage
                        } */}
                        <img src={"https://rota.erzurum.bel.tr" + rotaimage[index]?.resimyolu} className="img-fluid mb-3" style={{height:200,width:'100%',objectFit:"cover",borderRadius:10}}/>
                       
                        <h4>
                          {data.aciklama}
                        </h4>
                        <span>{data.detay}</span>
                      </Link>
                    )
                  }else{
                    return null
                  }
                 
                })
              }
            </div>
          </div>
      }
    </div>
  );
}

export default Rota;
