import React from 'react'
import Header from './components/Header'
import FooterBilgileri from './webservice/FooterBilgileri'
import ContentLoader, { Facebook } from 'react-content-loader'
import BasitOnarimLogo from '../assets/images/kudeb/Basit Onarım.jpg'
import EsasliOnarimLogo1 from '../assets/images/kudeb/Esaslı Onarım Örnek 1.jpg'
import EsasliOnarimLogo2 from '../assets/images/kudeb/Esaslı Onarım Örnek 2.jpg'

function BakimOnarim() {
    const [footerBilgileri, setFooterBilgisleri] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        FooterBilgileri.then((res) => {
            setFooterBilgisleri(res)
            setLoading(false)
            console.log(res)
            res.filter(name => name.menubaslik.indexOf('Basit') !== -1 ).map((data, index)=>{
                console.log(data.menubaslik)
            })
        })
    })
    return (
        <>
            <Header bgColor={true} yerlesim={true} />
            {
                loading ?
                    <div className="container" style={{ marginTop: 150 }}>
                        <ContentLoader viewBox="0 0 380 600">
                            <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
                            <rect x="0" y="40" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="80" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="120" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="160" rx="5" ry="5" width="90%" height="30" />
                        </ContentLoader>
                    </div>
                    : <div className="container" style={{ marginTop: 150 }}>
                        <h3 className="font-weight-bold">Basit Onarım</h3>
                        <img src={BasitOnarimLogo} className='img-fluid rounded' />
                        <ul>
                        {
                            footerBilgileri.filter(name => name.menubaslik.indexOf('Basit') !== -1 ).map((data, index) => {
                                    if(data.aciklama)
                                    return (
                                        <li>
                                        <div className="row   mt-4 p-4" key={index}>
                                            <div className="col-sm-11">
                                                <span>{data.aciklama}</span>
                                            </div>
                                        </div>
                                        </li>
                                    )
                            })
                        }
                        </ul>
                        <h3 className="mt-4 font-weight-bold">Bakım</h3>
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.indexOf('Bakım') !== -1).map((data, index) => {
                                    if (data.menubaslik === "Bakım")
                                        if(data.aciklama)
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul> 
                        <h3 className="mt-4 font-weight-bold">Esaslı Onarım</h3>
                        <img src={EsasliOnarimLogo1} className='img-fluid rounded' />
                        <img src={EsasliOnarimLogo2} className='img-fluid rounded mt-3' />

                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.indexOf('Esaslı Onarım') !== -1).map((data, index) => {
                                    if (data.menubaslik === "Esaslı Onarım" && data.aciklama)
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul>
                        <h3 className="mt-4 font-weight-bold">Yapı Grupları</h3>
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.indexOf('Yapı Grupları') !== -1).map((data, index) => {
                                    if (data.menubaslik === "Yapı Grupları" && data.aciklama)
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul>
                      
                    </div>
            }
        </>
    )
}

export default BakimOnarim
