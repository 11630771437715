import React, { useEffect, useState } from 'react';
import XMLParser from 'react-xml-parser';

function HavaDurumu({ navigation }) {
    const [gunBatimi, setgunBatimi] = useState('');
    const [gunDogumu, setgunDogumu] = useState('');
    const [havaBilgisi, setHavaBilgisi] = useState('');
    const [bugun, setBugun] = React.useState([]);
    const [havaSaat, setHavaSaat] = React.useState([]);
    const [yukleniyor, setYukleniyor] = useState(true);
    useEffect(() => {
        fetch(
            'https://api.openweathermap.org/data/2.5/weather?q=erzurum&appid=b57cd19491ea87e44a742b0fe61d0bc1',
        )
            .then(response => response.json())
            .then(res => {
                console.log(res);
                setBugun(res);
                var sec = res.sys.sunrise;
                var date = new Date(sec * 1000);
                var timestr = date.toLocaleTimeString();
                setgunDogumu(timestr);
                var sec1 = res.sys.sunset;
                var date1 = new Date(sec1 * 1000);
                var timestr1 = date1.toLocaleTimeString();
                setgunBatimi(timestr1);
                if (res.weather[0].description == 'clear sky') {
                    setHavaBilgisi('Açık Hava');
                }
                if (res.weather[0].description == 'few clouds') {
                    setHavaBilgisi('Bulutlu');
                }
                if (res.weather[0].description == 'scattered clouds') {
                    setHavaBilgisi('Dağınık Bulutlu');
                }
                if (res.weather[0].description == 'broken clouds') {
                    setHavaBilgisi('Parçalı Bulutlu');
                }
                if (res.weather[0].description == 'shower rain') {
                    setHavaBilgisi('Sağanak Yağışlı');
                }
                if (res.weather[0].description == 'rain') {
                    setHavaBilgisi('Yağışlı');
                }
                if (res.weather[0].description == 'thunderstorm') {
                    setHavaBilgisi('Fırtınalı');
                }
                if (res.weather[0].description == 'light snow') {
                    setHavaBilgisi('Kar Yağışlı');
                }
                if (res.weather[0].description == 'snow') {
                    setHavaBilgisi('Kar Yağışlı');
                }
                if (res.weather[0].description == 'mist') {
                    setHavaBilgisi('Sisli');
                }
                setYukleniyor(false)
            }).catch((err) => {
                console.log('Hata Var')
                console.log(err)
            })
    }, []);
    return (
        <div style={{ backgroundColor: 'rgba(255,255,255,.4)' ,display:"flex",alignItems:'center', borderRadius:10, padding:10,justifyContent:'center' }}>
            <div>
                <img
                    src={`http://openweathermap.org/img/wn/${!yukleniyor ? bugun.weather[0].icon : null
                        }@2x.png`}
                    style={{width:30,height:30}}
                />
            </div>
         
                <span
                    style={{ color: '#37383A', fontSize: 20, fontWeight: 'bold',marginLeft:10 }}>
                    {!yukleniyor
                        ? (parseFloat(bugun.main.temp) - 272.15).toFixed(0)
                        : null}{' '}
                    °
                </span>
        </div>

    );
}

export default HavaDurumu;
