import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import Logo from "../../assets/images/LOGO.png";
import EBBLogo from "../../assets/images/ebblogo.png";
import { DropdownButton, Dropdown, Button, Modal, Overlay, Offcanvas } from "react-bootstrap";
import Video from '../../assets/videos/tanitim.mp4';
import LOGOROTA from '../../assets/images/Rota_Erzurum_Logo_1.png';
import { Link, useHistory } from "react-router-dom";
import { HiMenuAlt3 } from 'react-icons/hi'
import ReactPlayer from "react-player";
import KategoriGetir from "../webservice/KategoriGetir";
const Header = ({ bgColor, yerlesim }) => {
  const [show, setShow] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [kategori,setKategori] = useState([])
  let history = useHistory();
  const searchItems = (e) => {
    if (e.length > 2) {
      fetch(`https://rota.erzurum.bel.tr/webservisi/DataSearch?search=${e}`)
        .then((res) => res.json())
        .then((response) => {
          setSearchList(response)
        })
    } else {
      setSearchList([])
    }

  }
  useEffect(()=>{
    KategoriGetir.then((res) => {
      setKategori(res);
    });
  },[])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showMenu, setShowMenu] = useState(false);
  const target = useRef(null);
  return (
    <div
      className="header p-2  "
      style={{ height: 100, borderWidth: bgColor ? 0 : 1, backgroundImage: bgColor ? "linear-gradient(to right, #093e52, #002735)" : "transparent", position: yerlesim ? "absolute" : "relative" }}
    >
      <div className={bgColor ? "container-fluid" : "container-fluid"} >
        <div className="row justify-content-between align-items-center mt-2">
          <div className="col-md-2 text-white">
            <ul className="menu__">
              <li>
                <a href="/index.html">
                  <img src={bgColor ? Logo : Logo} alt="image" style={{ height: bgColor ? 40 : 40 }} />
                </a>
              </li>

            </ul>
          </div>
          <div className="col-md-9 text-white">
            <div className="mobileMenu"  >
              <HiMenuAlt3 size={40} onClick={() => setShowMenu(!showMenu)} />
            </div>
            <ul className="menu__ sagMenu">
              <li>
                <Link to="/index.html" className="block">
                  Ana sayfa
                </Link>
                <div className="ani"></div>
              </li>
              <li>
                <Link to="/erzurumtarihi" >
                  Erzurum Tarihi
                </Link>
                <div className="ani"></div>
              </li>

              <li>
                <Link to="/rota" className="block">
                  Rota
                </Link>
                <div className="ani"></div>
              </li>
              <li>
                <Link to="/sanalturlar" className="block">
                  Sanal Turlar
                </Link>
                <div className="ani"></div>
              </li>
              <li>
                <a className="block" onClick={handleShow}>
                  Erzurum Tanıtım Videosu
                </a>
                <div className="ani"></div>
              </li>
              <li>
                <Link to="/map" className="block">
                  Haritada Keşfet <div class="blob blue"></div>
                </Link>
                <div className="ani"></div>
              </li>
              <li>
                <Link to="/canlikameralar" className="block">
                  Erzurum'u Seyret <div class="blob red"></div>

                </Link>
                <div className="ani"></div>
              </li>
              <li>
                <input type="text" placeholder="Ara..." className={!bgColor ? "searchBg" : "search"}
                  onChange={(e) => searchItems(e.target.value)}
                />
                {searchList.length > 0 ? <div className="searchscroll" style={{ backgroundColor: 'white', borderRadius: 12, width: 300, marginTop: 15, padding: 15, maxHeight: 600, position: "absolute", right: 0 }}>
                  {
                    searchList.map((data, index) => {
                      return (
                        <>
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, cursor: 'pointer' }} onClick={() => history.replace({
                            pathname: '/yerbilgisi',
                            search: `?query=${data.id}`,
                          })}>
                            <img src={data.resim ? `https://rota.erzurum.bel.tr/${data.resim}` : "https://rota.erzurum.bel.tr/image/Evraklar/resimyok.jpg"} className="img-fluid" style={{ width: 50, borderRadius: 10, height: 35 }} />
                            <span style={{ color: 'black', marginLeft: 10 }}>{data.adi}</span>
                          </div>
                          <hr style={{ backgroundColor: 'gray' }} />
                        </>
                      )
                    })
                  }
                </div> : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size={'lg'} >
        <Modal.Header closeButton>
          <Modal.Title>Erzurum Tanıtım Videosu</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ zIndex: 9000 }}>
          <ReactPlayer
            className=''
            url={Video}
            width='100%'
            height='100%'
            playing={true}
            controls={true}
          />
        </Modal.Body>

      </Modal>
      <Offcanvas show={showMenu} onHide={() => setShowMenu(false)} style={{backgroundImage: "linear-gradient(to bottom, rgb(9, 62, 82), rgb(0, 39, 53))",color:'white'}} >
        <Offcanvas.Header closeButton closeVariant="white" className="text-white" >
          <Offcanvas.Title >Rota Erzurum</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body onClick={()=>setSearchList([])} className="text-white">
          <div className="d-flex flex-column text-black align-items-center">
            <input type="text" placeholder="Ara..." className={!bgColor ? "searchBg" : "search"}
              onChange={(e) => searchItems(e.target.value)}
            />
            {searchList.length > 0 ? <div className="searchscroll shadow" style={{ backgroundColor: 'white',zIndex:1000, borderRadius: 12, width: 300, marginTop: 55, padding: 15, maxHeight: 600, position: "absolute", right: 0 ,left:'10%'}}>
              {
                searchList.map((data, index) => {
                  return (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, cursor: 'pointer' }} onClick={() => history.replace({
                        pathname: '/yerbilgisi',
                        search: `?query=${data.id}`,
                      })}>
                        <img src={data.resim ? `https://rota.erzurum.bel.tr/${data.resim}` : "https://rota.erzurum.bel.tr/image/Evraklar/resimyok.jpg"} className="img-fluid" style={{ width: 50, borderRadius: 10, height: 35 }} />
                        <span style={{ color: 'black', marginLeft: 10 }}>{data.adi}</span>
                      </div>
                      <hr style={{ backgroundColor: 'gray' }} />
                    </>
                  )
                })
              }
            </div> : null}
            <Link to="/index.html" className=" mb-4 mt-5" style={{color:'white',fontWeight:'500',fontSize:25}} >
              Ana sayfa
            </Link>
            <Link to="/erzurumtarihi" className=" mb-4" style={{color:'white',fontWeight:'500',fontSize:25}} >
              Erzurum Tarihi
            </Link>
            <Link to="/rota" className="block mb-4" style={{color:'white',fontWeight:'500',fontSize:25}}>
              Rota
            </Link>
            <Link to="/sanalturlar" className="block mb-4" style={{color:'white',fontWeight:'500',fontSize:25}}>
              Sanal Turlar
            </Link>
            <a className="block mb-4" onClick={handleShow } style={{color:'white',fontWeight:'500',fontSize:25}}>
              Erzurum Tanıtım Videosu
            </a>
            <Link to="/map" className="block mb-4" style={{color:'white',fontWeight:'500',fontSize:25}}>
              Haritada Keşfet <div class="blob blue"></div>
            </Link>
            <Link to="/canlikameralar" className="block mb-4" style={{color:'white',fontWeight:'500',fontSize:25}}>
              Erzurum'u Seyret <div class="blob red"></div>

            </Link>
            <h3 style={{color:'white',fontWeight:'500',fontSize:25 }} className="w-100 mt-4">Kategoriler</h3>
            <div className="mb-3"></div>
            {
                kategori.map((data, index) => {
                  return (

                    <div className="w-100 mb-1 box_ p-1 text-truncate" key={index} style={{overflow:'hidden' ,display:"inline-block" }} >
                      <Link to={{ pathname: `/kategori/${data.id}`, state: { id: data.id, tipadi: data.tipadi } }}
                        className="rounded box shadow-lg pt-3 pb-3 " >
                        <img src={`https://rota.erzurum.bel.tr/${data.ikon}`} alt="image" className="img-fluid" style={{ height: 40 }} />
                        <span className="mt-2 text-truncate" style={{ fontSize: 16, fontWeight: 700, wordWrap: "break-word",display:"inline-block",overflow:'hidden'  }}>{data.tipadi}</span>
                      </Link>
                    </div>
                  )
                })
              }
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default Header;


