import React, { useState } from "react";
import Header from "./components/Header";
import { Link, useLocation } from "react-router-dom";
import turistik from "../views/webservice/TuristikYerlerDetay";
import parse from "html-react-parser";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { BiMap, BiMapPin } from "react-icons/bi";
import ContentLoader, { Facebook } from 'react-content-loader'
import "leaflet/dist/leaflet.css";
import './QrPage.css'
import { usePosition } from './components/usePosition';
import 'react-slideshow-image/dist/styles.css'
import { Zoom } from 'react-slideshow-image';
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Detay_() {
  let query = useQuery();
  const { latitude, longitude, error } = usePosition();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [isYer, setIsYer] = React.useState(true)
  const [urunDetayi, setUrunDetayi] = React.useState([]);
  const [turistikYerAdi, setTuristikYerAdi] = React.useState("");
  const [querySearch,setQuerySearch] = useState(query.get("query"))
  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  React.useEffect(() => {
   
      if(query.get("query")){
        setUrunDetayi([])
        setTuristikYerAdi("")
        setImages([])
        turistik(0, query.get("query")).then(
            (res) => {

                if(res.length > 0) {
                    setUrunDetayi(res);
                    setTuristikYerAdi(res[0].turistikyeradi)
                    for (var i = 0; i < res[0].gorseller.length; i++) {
                      var gorsel = "https://rota.erzurum.bel.tr" + res[0].gorseller[i];
                      setImages((images) => [
                        ...images,
                        {
                          src: gorsel,
                          sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
                          width: 1,
                          height: 1,
                        },
                      ]);
                    }
                }else{
                  setIsYer(false);
                    setUrunDetayi([]);

                }
            
              setLoading(false)
      
            }
          );
      }else{
        setIsYer(false)
      }
  
  },[query.get("query")]);
  const zoomInProperties = {
    indicators: true,
    scale: 1.4,
    duration: 3000,
  }
  return (
    <div>
      <Header bgColor={true} yerlesim={true} />
        {
            isYer ? 
                loading ?
                  <>
                    <ContentLoader viewBox="0 0 380 120">
                      <rect x="0" y="20" rx="5" ry="5" width="100%" height="120" />
                    </ContentLoader>
                    <div className="container">
                      <ContentLoader viewBox="0 0 380 70">
                        <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
                        <rect x="0" y="40" rx="5" ry="5" width="100%" height="5" />
                        <rect x="0" y="50" rx="5" ry="5" width="86%" height="5" />
                        <rect x="0" y="60" rx="5" ry="5" width="60%" height="5" />
                      </ContentLoader>
                    </div>
                  </> : <>
                    <div className="dis">
                      <Zoom {...zoomInProperties} autoplay={images? images.length===1 ? false:true:null}>
                        {images.map((each, index) => (
                          <>
                            <div key={index} style={{ width: "100%" }} className="header_image mt-5">
                              <img style={{ width: "100%", height: 600 ,objectFit:"cover" }} src={each.src} />
                            
                            </div>
                          </>
                        ))}
                      </Zoom>
                      <p className="content">
                              {turistikYerAdi}
                              </p>
                        <div className="content1">
                              {
                            urunDetayi.length>0 ? urunDetayi.map((data, index) => {
                              if (data.kod.trim() === "KM")
                                return (
                                  <div key={index} style={{ display: 'flex', float: 'right' }}>
                                    <Link to={{ pathname: `/map`, state: { data: data } }} className="btn btn btn-primary"><BiMapPin size={25} style={{marginRight:10}} />Haritada Görüntüle</Link>
                                    <a
                                      href={`https://www.google.com/maps/dir/${latitude},${longitude}/${data.xkonum},${data.ykonum}`}
                                      target="_blank"
                                      className=" ml-4 " className="btn btn-danger ml-4"><BiMap size={25}  style={{marginRight:10}}/>Yol Tarifi Al</a>
                                  </div>
                                )
                            }) :null
                               
                          }
                          </div>
                    </div>
                    <div className="container">
                    </div>
                    {urunDetayi.length>0 ? urunDetayi.map((data, index) => {
                      if (data.kod.trim() === "HK") {
                        var veri = data.genelbilgiveri.replaceAll('font-family:Times New Roman,Times,serif', ' ');
                        var newData = veri.replaceAll('src="', 'src="https://rota.erzurum.bel.tr')
                        return (
                          <div className="container  " style={{ marginTop: 50, marginBottom: 50 }} key={index}>
                                <p style={{fontWeight:'700',fontSize:25,marginBottom:40}}>{turistikYerAdi}</p>

                            {parse(newData)}
                          </div>
                        );
                      }
        
                    }) :  null}
        
                    <div className="container ">
                      <h4>{urunDetayi.length>0 ? "GALERİ":null}</h4>
                    </div>
        
                    <div className={`container  ${images.length > 1 ? 'w-100' : 'w-25'}`}>
        
                      <Gallery photos={images} onClick={openLightbox} className="animate__animated animate__fadeInUp" />
                      <ModalGateway>
                        {viewerIsOpen ? (
                          <Modal onClose={closeLightbox}>
                            <Carousel
                              currentIndex={currentImage}
                              views={images.map((x) => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                              }))}
                            />
                          </Modal>
                        ) : null}
                      </ModalGateway>
                    </div>
        
        
        
                  </>
        :<div class="container" style={{marginTop:150}}>
        <div class="row">
            <div class="col-md-12">
                <div class="error-template">
               
                    <h1 style={{fontSize:100}}>
                        404 </h1>
                    <div class="error-details" style={{fontSize:25}}>
                        Aradığınız yer bilgisi bulunamadı.
                    </div>
                    <div class="error-actions">
                        <a href="/" class="btn btn-outline-danger "><span class="glyphicon glyphicon-home"></span>
                            Anasayfa</a>
                    </div>
                </div>
            </div>
        </div>
    </div>}


    </div>
  );
}

export default Detay_;

