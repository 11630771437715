import axios from 'axios';

function kategori(){
    return axios.get(`${process.env.REACT_APP_WEB_API}/KategorileriGetir?kullaniciadi=rotaerzurum&sifre=hs5352CXXv`)
    .then((res)=>{
        return res.data
    }).catch((err)=>{
        return err
    })
} 
export default kategori()