import React, { useEffect, useState } from "react";
import Header from "./components/Header";

import ContentLoader from "react-content-loader";

function SanalTurlar() {
  const [turlar, setTurlar] = useState([]);
  const [showTur, setShowTur] = useState("");
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const bootstrap = async () => {
      var res = await fetch('https://rota.erzurum.bel.tr/webservisi/SanalTurlar')
      var response = res.json();
      response.then((resRota) => {
        setTurlar(resRota)
        setShowTur(resRota[0].adres)
        setLoading(false)
      })

    }
    bootstrap()
  }, [])
  return (
    <div>
      <Header bgColor={true} yerlesim={true} />
      {
        loading ?
          <div className="container" style={{ marginTop: 150 }}>

            <ContentLoader viewBox="0 0 380 600">
              <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
              <rect x="0" y="40" rx="5" ry="5" width="90%" height="30" />
            </ContentLoader>

          </div>
          : <div className="container" style={{ marginTop: 150 }}>
            <h3 style={{fontWeight:'bold'}}>Sanal Turlar</h3>
            <hr style={{ backgroundColor: '#B5B4B4' }}></hr>
            {
                <iframe src={`${showTur}`} allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{height:720,width:'100%'}} ></iframe>
            }
            <div className="row mt-4">
              {
                turlar.map((data, index) => {
                  return (
                    <span className="col-md-3 mt-2  text-black text-center mb-3" style={{cursor:'pointer'}} key={index}
                    onClick={()=>setShowTur(data.adres)}
                    >
                        <img 
                        src={data.resim}
                        style={{
                            width: "100%",
                            float: "left",
                            height: 168,
                            borderRadius:20
                        }}
                        />
                       
                      <h6>
                        {data.adi}
                      </h6>
                    </span>
                  )
                })
              }
            </div>
          </div>
      }
    </div>
  );
}

export default SanalTurlar;
