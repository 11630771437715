import React from 'react'
import Header from './components/Header'
import FooterBilgileri from './webservice/FooterBilgileri'
import ContentLoader, { Facebook } from 'react-content-loader'
import KentselLogo from '../assets/images/kudeb/Kentsel Sit Alanı.jpg'
import TarihiSitLogo from '../assets/images/kudeb/Tarihi Sit Alanı.JPG'
import DogalTabiSitLogo from '../assets/images/kudeb/Doğal (Tabii) Sit Alanı.png'
import ArkeolojikSit1Derece from '../assets/images/kudeb/I. Derece Arkeolojik Sit Alanı Örnek.jpg'
import ArkeolojikSit3Derece from '../assets/images/kudeb/III. Derece Arkeolojik Sit Alanı Örnek.jpg'
function SitAlanlari() {
    const [footerBilgileri, setFooterBilgisleri] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        FooterBilgileri.then((res) => {
            setFooterBilgisleri(res)
            setLoading(false)
            console.log(res)
        })
    })
    return (
        <>
            <Header bgColor={true} yerlesim={true} />
            {
                loading ?
                    <div className="container" style={{ marginTop: 150 }}>
                        <ContentLoader viewBox="0 0 380 600">
                            <rect x="0" y="10" rx="5" ry="5" width="40%" height="8" />
                            <rect x="0" y="40" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="80" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="120" rx="5" ry="5" width="90%" height="30" />
                            <rect x="0" y="160" rx="5" ry="5" width="90%" height="30" />
                        </ContentLoader>
                    </div>
                    : <div className="container" style={{ marginTop: 150 }}>
                        <h3>Sit Alanları</h3>
                        {
                            footerBilgileri.filter(name => name.menubaslik.toUpperCase().indexOf('ALANI') !== -1).map((data, index) => {
                                if (data.menubaslik === "Sit Alanı")
                                    return (
                                        <div className="row shadow-sm rounded mt-4 p-4" key={index}>
                                            <div className="col-sm-1">
                                                <img src={`https://rota.erzurum.bel.tr/${data.ikon}`} className="img-fluid" />
                                            </div>
                                            <div className="col-sm-11">
                                                <span>{data.aciklama}</span>
                                            </div>
                                        </div>
                                    )
                            })
                        }
                        <h3 className="mt-4">ARKEOLOJİK SİT ALANI</h3>
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.toUpperCase().indexOf('ALANI') !== -1 && name.menubaslik === "ARKEOLOJİK SİT ALANI" ).map((data, index) => {
                                            return (
                                                <>
                                                <li>
                                                    <div className="row mt-2 p-4" key={index}>
                                                        <div className="col-sm-12">
                                                            <span>{data.aciklama}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        index === 0 ? <img className='img-fluid rounded' src={ArkeolojikSit1Derece} /> :index === 2 ? <img className='img-fluid rounded' src={ArkeolojikSit3Derece} />:null
                                                    }
                                                </li>
                                                </>
                                            )
                                })
                            }
                        </ul>
                        <h3 className="mt-4">KENTSEL SİT ALANI</h3>
                        <img className='img-fluid rounded' src={KentselLogo} />
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.toUpperCase().indexOf('ALANI') !== -1).map((data, index) => {
                                    if (data.menubaslik === "KENTSEL SİT ALANI")
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul>
                        <h3 className="mt-4">TARİHİ SİT ALANI</h3>
                        <img className='img-fluid rounded' src={TarihiSitLogo} />
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.toUpperCase().indexOf('ALANI') !== -1).map((data, index) => {
                                    if (data.menubaslik === "TARİHİ SİT ALANI")
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul>
                        <h3 className="mt-4">DOĞAL (TABİİ) SİT ALANI</h3>
                        <img className='img-fluid rounded' src={DogalTabiSitLogo}  />
                        <ul>
                            {
                                footerBilgileri.filter(name => name.menubaslik.toUpperCase().indexOf('ALANI') !== -1).map((data, index) => {
                                    if (data.menubaslik === "DOĞAL (TABİİ) SİT ALANI")
                                        return (
                                            <li>
                                                <div className="row mt-2 p-4" key={index}>
                                                    <div className="col-sm-12">
                                                        <span>{data.aciklama}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                })
                            }
                        </ul>
                    </div>
            }
        </>
    )
}

export default SitAlanlari
